import { makeObservable, observable } from 'mobx';

import { SensorResponseDto } from '../../shared/dto/sensor.response.dto';
import { BATTERY_LEVEL } from '../../shared/enum/oilfox/bateryLevel.enum';
import { QUANTITY_UNIT } from '../../shared/enum/oilfox/quantityUnit.enum';
import { VALIDATION_ERROR } from '../../shared/enum/oilfox/validationError.enum';

export class SensorModel {
  @observable
  public id: string = '';

  @observable
  public meteredAt: string = '';

  @observable
  public nextMetering: string = '';

  @observable
  public quantityUnit: QUANTITY_UNIT = QUANTITY_UNIT.LITERS;

  @observable
  public fillLevelPercent: number | undefined = undefined;

  @observable
  public fillLevelQuantity: number | undefined = undefined;

  @observable
  public daysReach: string | undefined = undefined;

  @observable
  public batteryLevel: BATTERY_LEVEL | undefined = undefined;

  @observable
  public validationError: VALIDATION_ERROR | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  public static fromDto(dto: SensorResponseDto): SensorModel {
    const model = new SensorModel();

    model.id = dto.id;
    model.meteredAt = dto.meteredAt;
    model.nextMetering = dto.nextMetering;
    model.quantityUnit = dto.quantityUnit;
    model.fillLevelPercent = dto.fillLevelPercent;
    model.fillLevelQuantity = dto.fillLevelQuantity;
    model.daysReach = dto.daysReach;
    model.batteryLevel = dto.batteryLevel;
    model.validationError = dto.validationError;

    return model;
  }

  public clone(): SensorModel {
    const cloned = new SensorModel();

    cloned.id = this.id;
    cloned.meteredAt = this.meteredAt;
    cloned.nextMetering = this.nextMetering;
    cloned.quantityUnit = this.quantityUnit;
    cloned.fillLevelPercent = this.fillLevelPercent;
    cloned.fillLevelQuantity = this.fillLevelQuantity;
    cloned.daysReach = this.daysReach;
    cloned.batteryLevel = this.batteryLevel;
    cloned.validationError = this.validationError;

    return cloned;
  }
}
