import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider, Typography } from '@mui/material';

import { useThemeContext } from '../../theme/ThemeContextProvider';

interface IGaugeMeterProps {
  value: number; // Value in percentage (0 to 100)
}

export const GaugeMeter = React.memo(function GaugeMeter({ value }: IGaugeMeterProps) {

  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const measurements = useMemo(() => {
    const radius = 80;
    const circumference = 2 * Math.PI * radius;
    const halfCircle = circumference / 2;
    const offset = (100 - value) / 100 * halfCircle;

    return {
      radius,
      circumference,
      halfCircle,
      offset,
      strokeDasharray: `${halfCircle} ${circumference}`
    };
  }, [value]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
        <svg width="200" height="120" viewBox="0 0 200 120">
          {/* Background Circle */}
          <circle
            cx="100"
            cy="100"
            r={measurements.radius}
            fill="transparent"
            stroke="#e0e0e0"
            strokeWidth="15"
            strokeDasharray={measurements.strokeDasharray}
            strokeLinecap={value > 0 ? 'round' : 'butt'}
            style={{
              transform: 'rotate(180deg)',
              transformOrigin: '100px 100px',
            }}
          />
          {/* Foreground Circle */}
          <circle
            cx="100"
            cy="100"
            r={measurements.radius}
            fill="transparent"
            stroke="#0D5F55"
            strokeWidth="15"
            strokeDasharray={measurements.strokeDasharray}
            strokeDashoffset={measurements.offset}
            strokeLinecap={value > 0 ? 'round' : 'butt'}
            style={{
              transform: 'rotate(180deg)',
              transformOrigin: '100px 100px',
              transition: 'stroke-dashoffset 0.3s ease-in-out',
            }}
          />
        </svg>
        <div style={{ marginTop: '-10px' }}>
          <Typography variant='text_md_semibold' color={theme.palette.text.primary}>
            {value}% {t('poi:feeding_site.full')}
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  );


});

GaugeMeter.displayName = 'GaugeMeter';
