import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Divider, Grid, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useVm } from '../../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { POI_TYPE } from '../../../../../../shared/enum';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';
import { GaugeMeter } from '../../../../../../toolkit/components/GaugeMeter';
import { PoiMarker } from '../../marker/PoiMarker';
import { ViewPoiInfoVm } from './ViewPoiInfoVm';

export interface IViewPoiInfoProps {
  poi: PoiModel;
  info: string | undefined;
  onPoiEdit: (poi: PoiModel) => void;
  canEditAndDeletePoi: boolean;
}

export function ViewPoiInfo(props: IViewPoiInfoProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const vm = useVm(ViewPoiInfoVm, props);

  const edit = useCallback(() => {
    props.onPoiEdit(props.poi);
  }, [props]);

  const renderEditButton = useMemo(() => (
    <Button
      onClick={edit}
      sx={{ mx: 'auto', paddingX: 8 }}
      color={'secondary'}
      variant="contained"
    >{t('poi:edit_poi_button')}</Button>
  ), [edit, t]);

  const renderForbiddenEditMessage = useMemo(() => (
    <Typography variant="h6" textAlign={'center'} paddingBottom={4}>{t('poi:forbidden_edit')}</Typography>
  ), [t]);

  const renderContent = vm.canEdit ? renderEditButton : renderForbiddenEditMessage;

  return (
    <Box width="100%">
      <Box paddingY={2} paddingX={4}>
        <Typography color={props.info ? 'initial' : 'text.details'}>
          {props.info || t('poi:missing_description')}
        </Typography>
      </Box>
      <Divider />
      {props.poi.type === POI_TYPE.FEEDER && (
        <ThemeProvider theme={theme}>
          <Box padding={4}>
            <Typography variant='text_md_semibold' color={theme.palette.text.primary}>
              {t('poi:feeding_site.food_stock')}
            </Typography>
            <Grid container justifyContent={'center'} alignItems={'center'}>
              <Grid item xs={12}>
                <GaugeMeter value={props.poi.sensorData?.fillLevelPercent ?? 0} />
              </Grid>
              {props.poi.sensorData?.daysReach && <Grid item xs={12} textAlign={'center'}>
                <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
                  <Trans
                    i18nKey={'poi:feeding_site.days_until_empty'}
                    values={{
                      days: props.poi.sensorData?.daysReach ?? 0,
                    }} />
                </Typography>
              </Grid>}
            </Grid>
          </Box>
          <Divider />
        </ThemeProvider>
      )}

      <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingX={4}>
        <Box paddingY={6}>
          <PoiMarker
            poi={props.poi}
            lat={0}
            lng={0}
            opacity={1}
            skiptransform={true}
            hideTooltip
            width={70}
            miniIconWidth={40}
            symbolWidth={34}
            hideShootingDirection
          />
        </Box>
        <React.Fragment>{renderContent}</React.Fragment>
      </Box>
    </Box>
  );
}
